import { BlockQuote } from "components/slices/BlockQuote";
import { Button } from "components/Button";
import styles from "./Review.module.css";

export const ReviewWrapper = ({ primary }) => {
  const { citee, date, review_text, reviews_link, rating } = primary;
  const d = new Date(date);
  const month = `0${d.getMonth() + 1}`.slice(-2);
  const formattedDate = `${d.getDate()}-${month}-${d.getFullYear()}`;

  return (
    <Review
      citee={`${citee} • ${formattedDate}`}
      quote={review_text}
      rating={rating}
      reviewUid={reviews_link.uid}
      type={reviews_link.type}
    />
  );
};

export const Review = ({ citee, quote, rating, reviewUid, type }) => {
  const linkType =
    type === "course_detail" ? "course_reviews" : "training_reviews";

  return (
    <div>
      <BlockQuote citee={citee} quote={quote} rating={rating} />
      {reviewUid && (
        <div className={styles.buttonContainer}>
          <Button link={{ type: linkType, uid: reviewUid }}>
            Bekijk alle reviews
          </Button>
        </div>
      )}
    </div>
  );
};
