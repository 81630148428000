import { IconChevron } from "components/Icons";
import { RichText } from "components/slices/RichText";
import { SMALL } from "shared/variables";
import { useBreakpoint } from "shared/useBreakpoint";
import { useEffect, useState } from "react";
import cx from "classnames";
import styles from "./Collapsible.module.css";

export const CollapsibleWrapper = ({ items }) => {
  const mappedItems = items.map(
    ({ collapsible_title, default_open, rich_text }) => ({
      title: collapsible_title,
      defaultOpen: default_open,
      text: rich_text,
    })
  );
  return <Collapsible items={mappedItems} />;
};

export const Collapsible = ({ items }) => (
  <div>
    {items.map(({ title, defaultOpen, text }, index) => (
      <CollapsibleItem
        key={index}
        defaultOpen={defaultOpen}
        title={title}
        text={text}
      />
    ))}
  </div>
);

const CollapsibleItem = ({ title, defaultOpen, text }) => {
  const breakpoint = useBreakpoint();
  const [isOpen, setIsOpen] = useState(breakpoint.isDesktop && defaultOpen);

  useEffect(() => {
    setIsOpen(breakpoint.isDesktop && defaultOpen);
  }, [breakpoint.isDesktop, defaultOpen]);

  return (
    <div className={styles.item}>
      <button className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <span
          className={cx(styles.headerInner, { [styles.headerOpen]: isOpen })}
        >
          <h3 className={styles.headerTitle}>{title}</h3>
          <IconChevron />
        </span>
      </button>
      <div className={cx(styles.content, { [styles.contentOpen]: isOpen })}>
        <RichText size={SMALL} content={text} />
      </div>
    </div>
  );
};
