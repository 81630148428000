import { Button } from "components/Button";
import { Link } from "components/Link";
import { RichText } from "components/slices/RichText";
import { GREEN } from "shared/variables";
import { SrcSet } from "components/SrcSet";
import { StarRating } from "components/StarRating";
import styles from "./CoursesList.module.css";

export const CoursesListWrapper = ({ courses }) => {
  const formattedCourses = [...courses]
    .map(
      ({
        review,
        uid,
        type,
        data: { hero_title, hero_image, summary, position },
      }) => ({
        image: { src: hero_image?.url, alt: hero_image?.alt },
        position,
        review,
        summary,
        title: hero_title,
        type,
        uid,
      })
    )
    .sort((a, b) => (a.position > b.position ? 1 : -1));

  return <CoursesList courses={formattedCourses} />;
};

export const CoursesList = ({ courses }) => (
  <div className={styles.list}>
    {courses.map((course) => (
      <CourseItem key={course.uid} {...course} />
    ))}
  </div>
);

const CourseItem = ({ title, image, summary, review, type, uid }) => {
  const reviewsType = type.replace("_detail", "_reviews");

  return (
    <div className={styles.item}>
      <Link type={type} uid={uid} className={styles.imageContainer}>
        {image?.src && (
          <SrcSet
            src={image.src}
            alt={image.alt}
            sizes={[
              { minWidth: 1200, width: 560, ratio: 1.5 },
              { minWidth: 1100, width: 540, ratio: 1.5 },
              { minWidth: 1024, width: 490, ratio: 1.5 },
              { minWidth: 900, width: 455, ratio: 1.5 },
              { minWidth: 768, width: 400, ratio: 1.5 },
              { minWidth: 700, width: 768, ratio: 1.5 },
              { minWidth: 600, width: 660, ratio: 1.5 },
              { minWidth: 500, width: 560, ratio: 1.5 },
              { minWidth: 400, width: 460, ratio: 1.5 },
              { minWidth: 345, width: 360, ratio: 1.5 },
              { minWidth: 1, width: 305, ratio: 1.5 },
            ]}
          />
        )}
        <div className={styles.overlay} />
        <h3
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: (title || "")
              .replace(/\*(.+)\*/gm, "<strong>$1</strong>")
              .replace(/\n/g, "<br />"),
          }}
        />
      </Link>
      <div className={styles.content}>
        <RichText>
          <p>{summary}</p>
        </RichText>
        <div className={styles.buttonReviewContainer}>
          <Button color={GREEN} link={{ type, uid }}>
            Bekijk deze{" "}
            {type.indexOf("training") === -1 ? "opleiding" : "training"}
          </Button>

          {review && (
            <Link
              className={styles.ratingContainer}
              type={reviewsType}
              uid={uid}
            >
              <StarRating rating={review.rating} />
              <span className={styles.ratingLink}>
                {review.amount} {review.amount === 1 ? "review" : "reviews"}
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
