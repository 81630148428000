import { Link } from "components/Link";
import { linkResolver } from "shared/prismic";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { SMALL, WHITE } from "shared/variables";
import cx from "classnames";
import styles from "./RichText.module.css";

export const RichTextWrapper = ({ primary, size }) => (
  <RichText content={primary.rich_text} size={size} />
);

export const RichText = ({ children = undefined, color = undefined, content = undefined, size = undefined }) => (
  <div
    className={cx(styles.richText, {
      [styles.small]: size === SMALL,
      [styles.white]: color === WHITE,
    })}
  >
    {content ? (
      <PrismicRichText
        render={content}
        linkResolver={linkResolver}
        serializeHyperlink={serializeHyperlink}
      />
    ) : (
      children
    )}
  </div>
);

const serializeHyperlink = (_, element, content) => {
  const { type, uid, url, link_type } = element.data;
  return (
    <Link key={uid || url} {...{ type, uid, url, link_type }}>
      {content}
    </Link>
  );
};
