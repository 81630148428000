import { EXTRALARGE } from "shared/variables";
import { Link } from "components/Link";
import { MaxWidth } from "components/MaxWidth";
import { RichText } from "components/slices/RichText";
import { SrcSet } from "components/SrcSet";
import { Button } from "components/Button";
import styles from "./HomeEvent.module.css";

export const HomeEvent = ({ image, title, text, link, linkLabel }) => (
  <Link {...link} className={styles.container}>
    <MaxWidth size={EXTRALARGE}>
      <div className={styles.card}>
        {image?.src && (
          <SrcSet
            alt={image?.alt}
            cropCenter={image?.cropCenter}
            className={styles.image}
            sizes={[
              { minWidth: 1024, width: 580, ratio: 1 },
              { minWidth: 900, width: 480, ratio: 1 },
              { minWidth: 768, width: 420, ratio: 1 },
              { minWidth: 700, width: 768, ratio: 3 / 2 },
              { minWidth: 600, width: 660, ratio: 3 / 2 },
              { minWidth: 500, width: 560, ratio: 3 / 2 },
              { minWidth: 400, width: 460, ratio: 3 / 2 },
              { minWidth: 345, width: 360, ratio: 3 / 2 },
              { minWidth: 1, width: 305, ratio: 3 / 2 },
            ]}
            src={image?.src}
          />
        )}
        <div className={styles.content}>
          <RichText>
            <h2 className={styles.title}>{title}</h2>
            <p>{text}</p>
          </RichText>
          {link && linkLabel && (
            <div className={styles.strongLinkContainer}>
              <Button as="span" link={link}>
                {linkLabel}
              </Button>
            </div>
          )}
        </div>
      </div>
    </MaxWidth>
  </Link>
);
