import { BlogGrid } from "components/BlogGrid";
import { CoursesListWrapper } from "components/CoursesList";
import { EventBlock } from "components/EventBlock";
import { HomeEvent } from "components/HomeEvent";
import { HomeHero } from "components/HomeHero";
import { HomeIntro } from "components/HomeIntro";
import { MaxWidth } from "components/MaxWidth";
import { NewsletterFormBlock } from "components/NewsletterForm";
import { Page } from "components/Page";
import { ImageWithContentWrapper } from "components/slices/ImageWithContent";
import { ReviewWrapper } from "components/slices/Review";
import { SpacingSection } from "components/SpacingSection";
import { getDocs, getSingle } from "shared/prismic";
import { EXTRALARGE } from "shared/variables";

export async function getStaticProps({ preview = null, previewData }) {
  const blogs = await getDocs({
    previewData,
    type: "blog_detail",
    pageSize: 3,
    options: {
      orderings: "[document.first_publication_date desc]",
    },
  });
  const coursesData = await getDocs({ previewData, type: "course_detail" });
  const doc = await getSingle({ previewData, type: "homepage" });
  const events = await getDocs({ previewData, type: "agenda_detail" });
  const trainingsData = await getDocs({ previewData, type: "training_detail" });

  const trainings = trainingsData.results || [];
  const courses = coursesData.results || [];
  const trainingsAndCourses = [...trainings, ...courses];

  return {
    props: {
      blogs: blogs.results || [],
      doc,
      events: events.results || [],
      preview,
      trainingsAndCourses,
    },
  };
}

const Index = ({ doc, trainingsAndCourses, events, blogs, preview }) => {
  const {
    about_us_image,
    about_us_link_label,
    about_us_link,
    about_us_rich_text,
    citee,
    coaches_image,
    coaches_link_label,
    coaches_link,
    coaches_rich_text,
    date,
    event_image_crop_center,
    event_image,
    event_link_label,
    event_link,
    event_text,
    event_title,
    events_title,
    hero_image,
    hero_text,
    hero_title,
    intro,
    rating,
    review_text,
    reviews_link,
    seo_description,
    seo_image,
    seo_title,
  } = doc.data;

  const renderHero = hero_image?.src || hero_title || hero_text;
  const renderEvent = event_image?.src || event_title || event_text;
  const renderCourses = trainingsAndCourses?.length;
  const renderEvents = events_title || events?.length;
  const renderReview = citee || date || review_text || reviews_link || rating;
  const renderCoaches = coaches_rich_text || coaches_image;
  const renderAboutUs = about_us_rich_text || about_us_image;

  return (
    <Page
      title={seo_title}
      description={seo_description}
      image={seo_image}
      preview={preview}
    >
      {renderHero && (
        <HomeHero
          description={hero_text}
          image={{ src: hero_image?.url, alt: hero_image?.alt }}
          title={hero_title}
        />
      )}
      {renderEvent && (
        <HomeEvent
          image={{
            cropCenter: event_image_crop_center,
            src: event_image?.url,
            alt: event_image?.alt,
          }}
          link={event_link}
          linkLabel={event_link_label}
          text={event_text}
          title={event_title}
        />
      )}
      {intro && (
        <SpacingSection size={EXTRALARGE}>
          <MaxWidth>
            <HomeIntro>{intro}</HomeIntro>
          </MaxWidth>
        </SpacingSection>
      )}
      {renderCourses && (
        <SpacingSection size={EXTRALARGE}>
          <MaxWidth size={EXTRALARGE}>
            <CoursesListWrapper courses={trainingsAndCourses} />
          </MaxWidth>
        </SpacingSection>
      )}
      {renderEvents && (
        <SpacingSection size={EXTRALARGE}>
          <EventBlock title={events_title} events={events} />
        </SpacingSection>
      )}
      {renderReview && (
        <MaxWidth>
          <ReviewWrapper
            primary={{ citee, date, review_text, reviews_link, rating }}
          />
        </MaxWidth>
      )}
      {renderCoaches && (
        <SpacingSection size={EXTRALARGE}>
          <MaxWidth size={EXTRALARGE}>
            <ImageWithContentWrapper
              primary={{
                image: coaches_image,
                link_label: coaches_link_label,
                link: coaches_link,
                rich_text: coaches_rich_text,
              }}
            />
          </MaxWidth>
        </SpacingSection>
      )}
      {renderAboutUs && (
        <SpacingSection size={EXTRALARGE}>
          <MaxWidth size={EXTRALARGE}>
            <ImageWithContentWrapper
              primary={{
                image_right: true,
                image: about_us_image,
                link_label: about_us_link_label,
                link: about_us_link,
                rich_text: about_us_rich_text,
              }}
            />
          </MaxWidth>
        </SpacingSection>
      )}
      <SpacingSection onlyBottom size={EXTRALARGE}>
        <MaxWidth size={EXTRALARGE}>
          <BlogGrid items={blogs} title="Onze laatste blogs" withBorderTop />
        </MaxWidth>
      </SpacingSection>

      <NewsletterFormBlock />
    </Page>
  );
};

export default Index;
