import { CollapsibleWrapper } from "components/slices/Collapsible";
import { RichText } from "components/slices/RichText";
import { SrcSet } from "components/SrcSet";
import { Button } from "components/Button";
import cx from "classnames";
import styles from "./ImageWithContent.module.css";

export const ImageWithContentWrapper = ({ items, primary }) => {
  const { rich_text, image, image_right, link, link_label } = primary;

  return (
    <ImageWithContent
      collapsibleItems={items}
      imageAlt={image.alt}
      imageSrc={image.url}
      link={link}
      linkLabel={link_label}
      reversed={image_right}
      richText={rich_text}
    />
  );
};

export const ImageWithContent = ({
  collapsibleItems,
  imageAlt,
  imageSrc,
  link,
  linkLabel,
  reversed,
  richText,
}) => (
  <div className={cx(styles.container, { [styles.reversed]: reversed })}>
    {imageSrc && (
      <div className={styles.imageContainer}>
        <SrcSet
          alt={imageAlt}
          src={imageSrc}
          sizes={[
            { ratio: 4 / 5, minWidth: 1024, width: 540 },
            { ratio: 4 / 5, minWidth: 900, width: 475 },
            { ratio: 4 / 5, minWidth: 768, width: 420 },
            { ratio: 1, minWidth: 700, width: 768 },
            { ratio: 1, minWidth: 600, width: 700 },
            { ratio: 1, minWidth: 500, width: 600 },
            { ratio: 1, minWidth: 400, width: 500 },
            { ratio: 1, minWidth: 345, width: 400 },
            { ratio: 1, minWidth: 1, width: 345 },
          ]}
        />
      </div>
    )}
    <div>
      {richText && <RichText content={richText} />}
      {collapsibleItems && collapsibleItems.length > 0 && (
        <div className={styles.collapsibleContainer}>
          <CollapsibleWrapper items={collapsibleItems} />
        </div>
      )}
      {link && linkLabel && (
        <div className={styles.strongLinkContainer}>
          <Button link={link}>{linkLabel}</Button>
        </div>
      )}
    </div>
  </div>
);
