import { StarRating } from "components/StarRating";
import styles from "./BlockQuote.module.css";

export const BlockQuoteWrapper = ({ primary }) => {
  const { quote, citee } = primary;
  return <BlockQuote quote={quote} citee={citee} />;
};

export const BlockQuote = ({ quote, citee, rating }) => (
  <blockquote className={styles.container}>
    {rating && (
      <div className={styles.rating}>
        <StarRating rating={rating} />
      </div>
    )}
    <p className={styles.quote}>“{quote}”</p>
    {citee && <footer className={styles.footer}>— {citee}</footer>}
  </blockquote>
);
