import { EXTRALARGE, WHITE } from "shared/variables";
import { IconDownArrow } from "components/Icons";
import { MaxWidth } from "components/MaxWidth";
import { RichText } from "components/slices/RichText";
import { smoothScrollToID } from "shared/smoothScrollToID";
import { SrcSet } from "components/SrcSet";
import styles from "./HomeHero.module.css";

const HOME_HERO_BOTTOM = "HOME_HERO_BOTTOM";

export const HomeHero = ({ title, description, image }) => (
  <>
    <div className={styles.container}>
      <MaxWidth size={EXTRALARGE}>
        <div className={styles.inner}>
          {image.src && (
            <SrcSet
              className={styles.image}
              alt={image.alt}
              src={image.src}
              sizes={[
                { minWidth: 1024, width: 900, ratio: 1 },
                { minWidth: 768, width: 640, ratio: 1 },
                { minWidth: 700, width: 768, ratio: 4 / 3 },
                { minWidth: 600, width: 700, ratio: 4 / 3 },
                { minWidth: 500, width: 600, ratio: 4 / 3 },
                { minWidth: 400, width: 500, ratio: 4 / 3 },
                { minWidth: 375, width: 400, ratio: 4 / 3 },
                { minWidth: 345, width: 375, ratio: 4 / 3 },
                { minWidth: 1, width: 345, ratio: 4 / 3 },
              ]}
            />
          )}

          <div className={styles.content}>
            <HomeHeroTitle>{title}</HomeHeroTitle>
            <RichText color={WHITE}>
              <p className={styles.description}>{description}</p>
            </RichText>
            <button
              className={styles.downButton}
              onClick={() => smoothScrollToID(HOME_HERO_BOTTOM)}
            >
              <IconDownArrow />
            </button>
          </div>
        </div>
      </MaxWidth>
    </div>
    <div id={HOME_HERO_BOTTOM} />
  </>
);

const HomeHeroTitle = ({ children = "" }) => (
  <h1
    className={styles.title}
    dangerouslySetInnerHTML={{
      __html: children
        .replace(/\*(.+)\*/gm, "<strong>$1</strong>")
        .replace(/\n/g, "<br />"),
    }}
  />
);
